<template>
	<main class="page-container container">
		<div class="section__top" :class="isMobileSmall ? 'mb-10' : 'mb-20'">
			<div class="section__top-left">
				<AppText
					:size="isMobileSmall ? 18 : isMobile ? 22 : 26"
					weight="600"
					:line-height="isMobileSmall ? 22 : isMobile ? 28 : 34"
				>
					{{ $t("registerOfActiveTrades") }}
				</AppText>
			</div>
		</div>

		<div class="section__body">
			<div class="table-block" :class="isMobileMedium ? 'mb-30' : 'mb-60'">
				<table>
					<thead>
						<tr>
							<th class="text-bold">{{ $t("lotNumber") }}</th>
							<th class="text-bold">{{ $t("numberOffer") }}</th>
							<th class="text-bold">{{ $t("productName") }}</th>
							<th class="text-bold">{{ $t("TNVEDCode") }} / {{ $t('enktCode') }}</th>
							<th class="text-bold">{{ $t("count") }}</th>
							<th class="text-bold">{{ $t("unitMeasurement") }}</th>
							<!-- <th class="text-bold">{{ $t("minimumAmount") }}</th>
							<th class="text-bold">{{ $t("maxAmount") }}</th> -->
							<!-- <th class="text-bold">{{ $t("sertificate") }}</th> -->
							<th class="text-bold">{{ $t("startDate") }}</th>
							<th class="text-bold">{{ $t("expirationDate") }}</th>
						</tr>
					</thead>
					<tbody>
						<template v-if="list && Array.isArray(list) && list.length">
							<tr class="text-center" v-for="item in list">
								<td>{{ item.lotNumber || "-" }}</td>
								<td>{{ item.offerNumber || "-" }}</td>
								<td>{{ item.productName || "-" }}</td>
								<td>{{ item.lotTnved || item.lotEnkt || "-" }}</td>
								<td>{{ item.quantity || "-" }}</td>
								<td>{{ item.measureName || "-" }}</td>
								<!-- <td>{{ item.minPart || "-" }}</td>
								<td>{{ item.maxPart || "-" }}</td> -->
								<!-- <td width="200">
									{{ item.isCertificate ? $t("certificated") : $t("notCertificated") }}
								</td> -->
								<td width="180">
									{{ item.beginDate ? $moment(item.beginDate).format("DD.MM.YYYY HH:mm:ss") : "-" }}
								</td>
								<td width="180">
									{{ item.endDate ? $moment(item.endDate).format("DD.MM.YYYY HH:mm:ss") : "-" }}
								</td>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td colspan="8">{{ $t("noData") }}</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>

			<pagination
				:total="this.total"
				:perPage="query.filters.take"
				@paginate="pagination($event)"
				class="paginationClassForActive"
			/>
		</div>
	</main>
</template>

<script>
import AppText from "../../components/shared-components/AppText";
import AppInput from "../../components/shared-components/AppInput";
import AppButton from "../../components/shared-components/AppButton";
import Pagination from "../../components/shared-components/Pagination";

export default {
	name: "index",
	components: { Pagination, AppButton, AppInput, AppText },
	data() {
		return {
			query: {
				filters: {
					skip: 0,
					take: 10,
				},
			},
			total: 0,
			list: [],
		};
	},
	async created() {
		await this.load();
	},
	methods: {
		async pagination(val) {
			this.query.filters.skip = val - this.query.filters.take;
			await this.load();
		},
		async load(page = this.query.filters.skip, take = this.query.filters.take) {
			try {
				this.$store.commit("setLoadingStatus", true);
				let params = {
					skip: page,
					take: take,
				};
				const response = await this.$api.get("/api-shop/LotRequest/GetLotsInTrade", { params });
				this.list = response.data.result.result;
				this.total = response.data.result.count;
			} finally {
				this.$store.commit("setLoadingStatus", false);
			}
		},
	},
};
</script>

<style lang="scss">
.paginationClassForActive {
	display: flex;
	margin-bottom: 20px;
	justify-content: center;
	width: 100%;
	.pagination {
		display: flex !important;
	}
}
</style>

